import React from "react"
import { SnowTubing } from "../components/SnowTubing"
import { Layout } from "../components/Layout"

const SnowTubingPage = () => {
  return (
    <Layout>
      <SnowTubing />
    </Layout>
  )
}

export default SnowTubingPage
